import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';

//Tables
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MyTable from '../../utils/MyTable';
//redux
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../actions';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { unoTheme } from "../../../assets/styles/unoTheme";


import {
    applyMaskAccount,
    applyMaskAmount,
    applyMaskAmountFreeDecimals,
    formatPtStringDateToAnbimaDate,
    getLastDayInMonthByMonthAndYear,
    OperationStringMap
} from '../../utils/utils';

import CircularProgress from '@material-ui/core/CircularProgress';

//Controllers
import { Divider } from '@material-ui/core';
import moment from 'moment';
import { getTransactionsByAsset } from '../../../API/transaction';
import { getLocalQuotasByFundIdAndPeriods } from '../../../API/fund_local_quotas';
import _ from 'lodash';



const useStyles = makeStyles((theme) => ({
    root: {
        //height: '100vh',
        width: '100%',
    },

    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: 'solid 0.5px #f4f4f4',

        background: unoTheme.hoverTable,

        '& .closeIcon': {
            padding: theme.spacing(3),
        }
    },

    main: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',

        '& .tableTransactions': {
            height: 'calc(100vh - 123px)',
        }

    },

    myDatePicker: {
        marginBottom: '16px',
    },

    labelCellTop: {
        fontSize: '13px',
        fontWeight: '600'
    },
    labelCellBottom: {
        fontSize: '13px',
        fontColor: unoTheme.defaultTextColor
    },

    bottom: {
        display: 'flex',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },

    buttonsBottom: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
    },

    spacingRight2: {
        marginRight: theme.spacing(2),
    },

    noMarginTop: {
        marginTop: "0px",
    },

    hiddenIcon: {
        visibility: 'hidden',
    },

    rightDivBackdrop: {
        zIndex: 3,
        backgroundColor: 'white',
        position: 'absolute',
        opacity: 0.85,
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        flexDirection: 'column',
        color: unoTheme.mainColor,
        alignItems: 'center',
        justifyContent: 'center',

        '& .progress': {
            color: unoTheme.mainColor
        }
    },
    validationMessage: {
        color: unoTheme.errorColor,
        fontSize: '14px',
        textAlign: 'right',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },

    infosFundContent: {
        height: '100%',
        width: '100%',
        overflowY: 'scroll',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    leftHeader: {
        display: 'flex',
        padding: "24px",
        flexDirection: 'column',
        maxWidth: "calc(100% - 96px)"
    },

    fundLabel: {
        fontSize: '20px',
        fontWeight: 'bold',
        color: '#1C2228',
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },

    cnpjLabel: {
        fontSize: '14px',
        color: unoTheme.secondaryColor,
    },

    overflowEllipsis: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    green: {
        color: unoTheme.greenMoney + ' !important'
    },
    red: {
        color: unoTheme.errorColor + ' !important',
    },
    blue: {
        color: 'blue !important',
    },

}));

export default function IPC14(props) {

    const classes = useStyles();

    const dispatch = useDispatch();
    const clientOn = useSelector(state => state.clientOn)
    const currentUser = useSelector(state => state.currentUser)

    const { content } = props

    const [transactions, setTransactions] = useState([]);

    const [lines, setLines] = useState([]);

    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [messageBackdrop, setMessageBackdrop] = useState("");

    const blankLine = {
        date: "blankLine",
        description: "=",
        amount: "=",
        qtdQuotas: "=",
        quotaValue: "=",
        balanceQuotas: "=",
        profit: "=",
        pasep: "=",
        balanceApplicationsQuotas: "=",
    }

    const formatLinesReturnsIPC14 = async (selectedAsset, transactions) => {

        let currentBalanceQuotas = parseFloat(selectedAsset.init_quotas);
        let currentBalance = parseFloat(selectedAsset.init_balance);

        const fullTransactionsArray = transactions.map((el) => ({
            date: moment.utc(el.transaction_date).format("DD/MM/YYYY"),
            amount: parseFloat(el.amount),
            operation_id: el.operation_id,
        }));

        fullTransactionsArray.unshift({
            date: moment.utc(selectedAsset.asset_init).format("MM/YYYY"),
            amount: currentBalance,
            operation_id: 1,
            qtdQuotas: currentBalanceQuotas,
            quotaValue: currentBalance / currentBalanceQuotas,
        });

        const applicationsArray = _.cloneDeep(fullTransactionsArray.filter(el => el.operation_id === 1));
        const rescuesArray = fullTransactionsArray.filter(el => el.operation_id === 2);

        const periodsToGetQuotas = [];
        fullTransactionsArray.forEach(el => {
            const periodApplication = el.date.substring(3);
            if (!periodsToGetQuotas.includes(periodApplication)) {
                periodsToGetQuotas.push(periodApplication);
            }
        });

        console.log("periodsToGetQuotas: ", periodsToGetQuotas);

        const mapQuotasByPeriod = {};
        if (periodsToGetQuotas.length) {

            const response = await getLocalQuotasByFundIdAndPeriods(selectedAsset.fund_id, periodsToGetQuotas)
            console.log("response cotas: ", response);
            if (response.success) {
                response.body.forEach(quotas => {
                    mapQuotasByPeriod[`${quotas.month.toString().padStart(2, "0")}/${quotas.year}`] = JSON.parse(quotas.json_quotas);
                });
            }
        }

        console.log("mapQuotasByPeriod: ", mapQuotasByPeriod);

        const formattedLines = [];
        //DATA	
        //DESCRIÇÃO	
        //VALOR	
        //QUANTIDADE DE COTAS	
        //VALOR COTA	
        //SALDO COTAS	
        //RECEITA ORÇAMENTÁRIA	 
        //PASEP 	
        //SALDO DAS APLICAÇÕES
        formattedLines.push({
            date: moment.utc(selectedAsset.asset_init).format("MM/YYYY"),
            description: "SALDO ANTERIOR",
            amount: currentBalance,
            qtdQuotas: currentBalanceQuotas,
            quotaValue: "",
            balanceQuotas: "",
            profit: "",
            pasep: "",
            balanceApplicationsQuotas: currentBalanceQuotas,
        });

        const getQuotaInfos = (amount, date, periodQuotas) => {

            if (periodQuotas) {
                const quota = periodQuotas.find(el => el.data_referencia == formatPtStringDateToAnbimaDate(date));
                // console.log("Quota: ", quota);
                if (quota) {

                    return {
                        qtdQuotas: amount / quota.valor_cota,
                        quotaValue: quota.valor_cota,
                    };
                }
            }

            // if (transaction.qtdQuotas && transaction.quotaValue) {

            //     return {
            //         qtdQuotas: transaction.qtdQuotas,
            //         quotaValue: transaction.quotaValue,
            //     };
            // }
            return {
                qtdQuotas: null,
                quotaValue: null
            };
        }

        const getApplicationsToRescue = (rescueAmount) => {

            let currentRescueAmount = rescueAmount;
            const theApplicationsToRescue = [];
            for (let index = 0; index < applicationsArray.length; index++) {
                const application = applicationsArray[index];

                if (application.amount > 0) {

                    if (application.amount > currentRescueAmount) {
                        application.amount -= currentRescueAmount;
                        application.rescuedAmount = currentRescueAmount;
                        theApplicationsToRescue.push(application);
                        break;
                    } else {

                        application.rescuedAmount = application.amount;
                        application.amount -= currentRescueAmount;
                        currentRescueAmount = application.amount * (-1);
                        application.amount = 0;
                        theApplicationsToRescue.push(application);
                    }

                }
            }

            return theApplicationsToRescue;
        }


        let currentPeriod = fullTransactionsArray[1].date.substring(3);
        console.log("currentPeriod: ", currentPeriod);
        fullTransactionsArray.forEach((transactionLoop, index) => {

            if (index !== 0) {

                const periodLoop = transactionLoop.date.substring(3);

                /////////////////////////////
                //Fechando período mensal////
                /////////////////////////////
                if (periodLoop != currentPeriod) {

                    const splittedCurrentPeriod = currentPeriod.split("/");
                    const lastDayMonth = getLastDayInMonthByMonthAndYear(parseInt(splittedCurrentPeriod[0]), parseInt(splittedCurrentPeriod[1]));

                    //LINHA SALDO ATUAL
                    formattedLines.push({
                        date: lastDayMonth,
                        description: "SALDO ATUAL",
                        amount: currentBalance,
                        qtdQuotas: currentBalanceQuotas,
                        quotaValue: "",
                        balanceQuotas: currentBalanceQuotas,
                        profit: "",
                        pasep: "",
                        balanceApplicationsQuotas: "",
                    });

                    formattedLines.push(blankLine);

                    //LINHA SALDO ANTERIOR
                    formattedLines.push({
                        date: lastDayMonth,
                        description: "SALDO ANTERIOR",
                        amount: currentBalance,
                        qtdQuotas: currentBalanceQuotas,
                        quotaValue: "",
                        balanceQuotas: "",
                        profit: "",
                        pasep: "",
                        balanceApplicationsQuotas: "",
                    });

                    currentPeriod = periodLoop;
                }
                /////////////////////////////
                /////////////////////////////

                const { operation_id } = transactionLoop;

                if (operation_id === 1) { //APLICAÇÃO

                    currentBalance += transactionLoop.amount;
                    const { qtdQuotas, quotaValue } = getQuotaInfos(
                        transactionLoop.amount,
                        transactionLoop.date,
                        mapQuotasByPeriod[transactionLoop.date.substring(3)]
                    );
                    currentBalanceQuotas += qtdQuotas;

                    //LINHA DE APLICAÇÃO
                    formattedLines.push({
                        date: transactionLoop.date,
                        description: "APLICAÇÃO",
                        amount: transactionLoop.amount,
                        qtdQuotas: qtdQuotas,
                        quotaValue: quotaValue,
                        balanceQuotas: currentBalanceQuotas,
                        profit: "",
                        pasep: "",
                        balanceApplicationsQuotas: qtdQuotas,
                    });

                } else { //RESGATE

                    const rescuePeriod = transactionLoop.date.substring(3);

                    // console.log("rescue.date.substring(3): ", rescue.date.substring(3));
                    const { qtdQuotas, quotaValue } = getQuotaInfos(
                        transactionLoop.amount,
                        transactionLoop.date,
                        mapQuotasByPeriod[rescuePeriod]
                    );
                    // console.log("qtdQuotas, quotaValue: ", qtdQuotas, quotaValue);

                    //LINHA DE RESGATE
                    formattedLines.push({
                        date: transactionLoop.date,
                        description: "RESGATE",
                        amount: transactionLoop.amount,
                        qtdQuotas: qtdQuotas,
                        quotaValue: quotaValue,
                        balanceQuotas: currentBalanceQuotas - qtdQuotas,
                        profit: "",
                        pasep: "",
                        balanceApplicationsQuotas: parseFloat(selectedAsset.init_quotas),
                    });

                    const theApplications = getApplicationsToRescue(transactionLoop.amount);

                    console.log("theApplications: ", theApplications);

                    theApplications.forEach(application => {

                        console.log("RESCUED APPLICATION: ", application);

                        application.rescuedDate = transactionLoop.date;

                        const {
                            qtdQuotas: qtdQuotasApplication,
                            quotaValue: quotaValueApplication
                        } = getQuotaInfos(
                            application.rescuedAmount,
                            transactionLoop.date,
                            mapQuotasByPeriod[rescuePeriod]
                        );

                        currentBalance -= application.rescuedAmount;
                        currentBalanceQuotas -= qtdQuotasApplication;
                        //LINHA DE APLICAÇÃO
                        formattedLines.push({
                            date: "",
                            description: "APLICAÇÃO " + application.date,
                            amount: application.rescuedAmount,
                            qtdQuotas: qtdQuotasApplication,
                            quotaValue: quotaValueApplication,
                            balanceQuotas: "",
                            profit: "",
                            pasep: "",
                            balanceApplicationsQuotas: parseFloat(selectedAsset.init_quotas),
                        });
                    });

                }

            }


        });

        return formattedLines;
    }

    const loadTransactions = async (selectedAsset) => {
        const response = await getTransactionsByAsset(selectedAsset.id);

        console.log("response: ", response);
        if (response.success) {
            setTransactions(response.body.rows);
        }
        setLines(await formatLinesReturnsIPC14(selectedAsset, response.body.rows));
    }

    useState(() => {
        if (content?.data) {
            const { data: asset } = content;
            console.log("asset: ", asset);
            loadTransactions(asset)
        }
    }, [])

    return (
        <div className={classes.root}>

            <Paper elevation={0}>
                <div className={classes.header}>

                    <div className={classes.leftHeader}>

                        <Typography
                            align={'left'}
                            style={{ color: unoTheme.secondaryColor }}>
                            IPC14
                        </Typography>
                        <Typography
                            align={'left'}
                            className={classes.fundLabel}>
                            {content?.data?.fund_name ?? ""}

                        </Typography>

                        <Typography
                            align={'left'}
                            className={classes.cnpjLabel}>
                            {content?.data?.cnpj ?? ""}
                            <span style={{ marginLeft: "12px" }}>CC: {content?.data?.number_account ? applyMaskAccount(content.data.number_account) : "-"}</span>
                            <span style={{ marginLeft: "12px" }}>Ag: {content?.data?.agency ?? "-"}</span>
                        </Typography>
                    </div>

                    <IconButton className="closeIcon" onClick={() => dispatch(allActions.rightContentActions.setRightContent(null))}>
                        <CloseIcon />
                    </IconButton>

                </div>
            </Paper>

            <Divider />
            <div className={classes.main}>
                <div className='tableTransactions'>
                    <MyTable stickyHeader
                        selectable
                        size="small">
                        <colgroup>
                            <col width="10%" />
                            <col width="15%" />
                            {/*<col width="10%" />
                            <col width="10%" />
                            <col width="10%" /> */}
                        </colgroup>
                        <TableHead>
                            <TableRow>

                                <TableCell align="center">DATA</TableCell>
                                <TableCell align="left">DESCRIÇÃO</TableCell>
                                <TableCell align="center">VALOR</TableCell>
                                <TableCell align="center">QUANTIDADE DE COTAS</TableCell>
                                <TableCell align="center">VALOR COTA</TableCell>
                                <TableCell align="center">SALDO COTAS</TableCell>
                                <TableCell align="center">RECEITA ORÇAMENTÁRIA</TableCell>
                                <TableCell align="center">PASEP</TableCell>
                                {/* <TableCell align="center">SALDO DAS APLICAÇÕES</TableCell> */}

                            </TableRow>
                        </TableHead>


                        <TableBody>
                            {lines.map((row, index) => row.date == "blankLine" ?
                                (<TableRow
                                    key={'asset-returns-ipc14-lines' + index}>

                                    <TableCell align="center">=</TableCell>
                                    <TableCell align="center">=</TableCell>
                                    <TableCell align="center">=</TableCell>
                                    <TableCell align="center">=</TableCell>
                                    <TableCell align="center">=</TableCell>
                                    <TableCell align="center">=</TableCell>
                                    <TableCell align="center">=</TableCell>
                                    <TableCell align="center">=</TableCell>
                                    {/* <TableCell align="center">{row.balanceApplicationsQuotas ? applyMaskAmount(row.balanceApplicationsQuotas) : ""}</TableCell> */}


                                </TableRow>)
                                :
                                (

                                    <TableRow
                                        key={'asset-returns-ipc14-lines' + index}>

                                        <TableCell align="center">{row.date}</TableCell>
                                        <TableCell align="left">{row.description}</TableCell>
                                        <TableCell align="center" className={row.description == "RESGATE" ? classes.red : classes.green}>{applyMaskAmount(row.amount, true)}</TableCell>
                                        <TableCell align="center">{row.qtdQuotas ? applyMaskAmount(row.qtdQuotas) : ""}</TableCell>
                                        <TableCell align="center">{row.quotaValue ? applyMaskAmountFreeDecimals(row.quotaValue, null, 5) : ""}</TableCell>
                                        <TableCell align="center">{row.balanceQuotas ? applyMaskAmount(row.balanceQuotas) : ""}</TableCell>
                                        <TableCell align="center">{row.profit ? applyMaskAmount(row.profit, true) : ""}</TableCell>
                                        <TableCell align="center">{row.pasep ? applyMaskAmount(row.pasep) : ""}</TableCell>
                                        {/* <TableCell align="center">{row.balanceApplicationsQuotas ? applyMaskAmount(row.balanceApplicationsQuotas) : ""}</TableCell> */}


                                    </TableRow>

                                ))}
                        </TableBody>

                    </MyTable>
                </div>

            </div>

            {
                openBackdrop ?
                    <div className={classes.rightDivBackdrop}>
                        <CircularProgress className={'progress'} />
                        {
                            messageBackdrop != "" ?
                                <Typography variant={'body1'} style={{ marginTop: '16px' }}>
                                    {messageBackdrop}
                                </Typography>
                                : null
                        }

                    </div> : null
            }

        </div>
    );
}