import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

//Controllers

import { headerSearch, tabs, unoTheme } from '../../../../../assets/styles/unoTheme';


import { TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { getClientAllAssetsByDateAndRegime, getFundsByAccountId } from '../../../../../API/fund';
import MyTable from '../../../../utils/MyTable';
import DefaultScrollWithHeaderContent from '../../../../utils/DefaultScrollWithHeaderContent';
import allActions from '../../../../../actions';
import { getClientAllAssetsTitleByDateAndRegime } from '../../../../../API/title';
import { AssetsAPI } from '../../../../../API/assets';
import { applyMaskAccount } from '../../../../utils/utils';
import moment from 'moment';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    green: {
        color: 'green'
    },
    red: {
        color: 'red',
    },
    headerSearch: headerSearch,
    inputSearch: {
        width: "300px",
        background: "white"
    },
    toStickyHeader: {
        display: 'flex',
        height: '100%',
    },
    dirName: {
        color: 'black',
        cursor: 'pointer',
        '&:hover': {
            color: 'blue'
        }
    },
    file: {
        cursor: 'pointer',
        '&:hover': {
            color: 'blue',
            '& svg': {
                color: 'blue'
            }
        }

    },
    tabs: tabs,

    demonstrativeContainer: {
        overflowY: 'scroll',
        width: '100%',
        padding: '24px',
        display: 'flex',
        flexDirection: 'column',

        '& .demonstrativeFund': {

            marginBottom: '32px',

            '& .fundName': {
                color: unoTheme.defaultTextColor,
                paddingBottom: '16px',
                textAlign: 'left',
                fontWeight: 'bold',
                fontSize: '18px',

            },

            '& .demonstrativeTopInfos': {
                display: 'flex',
                flexDirection: 'row',
                paddingBottom: '16px',

                '& .demonstrativeTopInfosLeft': {
                    paddingRight: '16px',
                },
                '& .demonstrativeTopInfosRight': {
                    borderLeft: 'solid 1px ' + unoTheme.mainColor,
                    paddingLeft: '16px',
                    paddingRight: '16px',
                }
            }
        }
    },
    tableBlade: {

        tableLayout: 'fixed',


        '& .MuiTableRow-head': {
            '& .MuiTableCell-root': {
                fontSize: '12px',
                padding: '4px 4px',
                fontWeight: "bold",
                color: '#1C2228',
                wordWrap: 'break-word',
            },

            '& .MuiTableCell-root:first-child': {
                paddingLeft: '16px',
                borderRight: 'solid 1.5px #E4E7EB'
            },
            '& > *': {
                borderBottom: 'unset',
            },
        },

        '& .MuiTableBody-root': {

            '& .MuiTableRow-root': {
                borderRadius: '10px',
                '&:nth-of-type(odd)': {
                    backgroundColor: unoTheme.secondaryTuplaTable,
                    //backgroundColor: 'red',
                },

                '&:nth-of-type(even)': {
                    backgroundColor: unoTheme.mainColor,
                    color: 'white',

                    '& .MuiTableCell-root': {
                        color: 'white',
                    },
                    //backgroundColor: 'red',
                },

                '& > *': {
                    borderBottom: 'unset',
                },
            },

            '& .MuiTableCell-root': {
                fontSize: '12px',
                padding: '12px 4px',
                color: '#768191',
            },

            '& .MuiTableCell-root:first-child': {
                borderRight: 'solid 1.5px #E4E7EB',
                paddingLeft: '16px',
                borderTopLeftRadius: '10px',
                borderBottomLeftRadius: '10px',
            },

            '& .MuiTableCell-root:last-child': {
                borderTopRightRadius: '10px',
                borderBottomRightRadius: '10px',
            },
        },


    },

    gridPaperTable: {
        borderRadius: '10px',
    },

    ellipsis: {
        whiteSpace: "nowrap", /* Impede a quebra de linha */
        overflow: "hidden", /* Garante que o texto extra seja ocultado */
        textOverflow: "ellipsis",
    },


});


export default function ReturnsIPC14Tab(props) {

    const { searchText, selectedAccount, selectedRegime } = props;

    console.log("selectedAccount xxx:", selectedAccount);

    const classes = useStyles();
    const dispatch = useDispatch();

    const clientOn = useSelector(state => state.clientOn)
    const [assets, setAssets] = useState([]);

    const [innerLoad, setInnerLoad] = useState({ emptyMessage: "", loadMessage: "Carregando ativos...", size: "small" });

    const loadAssets = async (regimeId, account) => {

        setAssets([]);

        const clientId = clientOn?.client?.id;

        if (clientId) {

            const response = await AssetsAPI.getAllAssetsClient(clientId, null, regimeId, account?.id ?? 0);
            if (response.success) {

                const responseAssets = response.body.rows;
                console.log("responseAssets: ", responseAssets);
                setAssets(responseAssets);
                setInnerLoad(null);

            } else {
                setInnerLoad({ emptyMessage: "Nenhum ativo encontrado" });

            }
        }

    }

    useEffect(() => {
        console.log("selectedAccount: ", selectedAccount)
        loadAssets(selectedRegime, selectedAccount);
    }, [selectedRegime, selectedAccount])

    return (
        <div className={classes.root}>

            <DefaultScrollWithHeaderContent innerLoad={innerLoad}>

                <MyTable stickyHeader
                    selectable
                    size="small">
                    <colgroup>
                        <col width="45%" />
                        <col width="20%" />
                        <col width="10%" />
                        <col width="10%" />
                        <col width="10%" />
                    </colgroup>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Fundo</TableCell>
                            <TableCell align="center">CNPJ</TableCell>
                            <TableCell align="center">CC</TableCell>
                            <TableCell align="center">Ag.</TableCell>
                            <TableCell align="center">Início</TableCell>
                        </TableRow>
                    </TableHead>


                    <TableBody>
                        {assets.map((row, index) => (

                            <TableRow
                                key={'client-assets-' + index}
                                onClick={() => dispatch(allActions.rightContentActions.setRightContent({
                                    path: 'returnsFundsIpc14',
                                    data: row,
                                }))}
                            >

                                <TableCell align="left" >
                                    <span className={classes.ellipsis}>{row.fund_name}</span>
                                </TableCell>
                                <TableCell align="center">{row.cnpj}</TableCell>
                                <TableCell align="center">{applyMaskAccount(row.number_account)}</TableCell>
                                <TableCell align="center">{row.agency ?? "-"}</TableCell>
                                <TableCell align="center">{moment.utc(row.asset_init).format("DD/MM/YYYY")}</TableCell>

                            </TableRow>

                        ))}
                    </TableBody>

                </MyTable>

            </DefaultScrollWithHeaderContent>

        </div>
    );
}



